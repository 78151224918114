<template>

    <section class="section">
        <div class="container has-text-left">
          <script v-html=faqJsonLd() type="application/ld+json"/>

            <div class="content">
            <h1 class="title is-4">Was ist Familienpflege?</h1>
            <p>
                Ein Hilfsangebot für Mütter, die in einer Notsituation ihre Kinder nicht versorgen können, sie
                unterstützt die Familie in ihrer häuslichen Umgebung.
                Von den Krankenkassen wird Familienpflege auch Haushaltshilfe genannt.
            </p>
            </div>

            <div class="content">
            <h1 class="title is-4">Was ist eine Familienpflegerin?</h1>

            <p>
                Sie ist eine staatlich geprüfte und anerkannte Fachkraft. Ihre Ausbildung umfasst Hauswirtschaft,
                Pädagogik aller Altersstufen, häusliche Säuglingspflege, Krankenpflege und Altenpflege. Sie arbeitet
                selbständig in Absprache mit der Familie.
                Sie unterliegt der Schweigepflicht.
            </p>
            </div>

            <div class="content">
            <h1 class="title is-4">Was macht eine Familienpflegerin?</h1>

            <ul>
                <li>sie betreut die Kinder</li>
                <li>sie sorgt für regelmäßigen Kindergarten- und Schulbesuch der Kinder</li>
                <li>sie gibt Hausaufgabenhilfe</li>
                <li>sie beschäftigt die Kinder altersgerecht</li>
                <li>sie begleitet die Kinder zu allen nötigen Terminen, z. B. Arzt, Logopäde, Ergotherapie,
                    Musikunterricht usw.
                </li>
                <li>sie sorgt dafür, dass die Kinder ihre sozialen Beziehungen (Freunde, Vereine, usw.) aufrecht
                    erhalten können
                </li>
                <li>sie übernimmt Säuglings- und Kinderpflege und Wöchnerinnenpflege</li>
                <li>sie pflegt und betreut auch alle anderen Familienmitglieder ob erkrankt oder behindert</li>
                <li>sie führt den Haushalt: einkaufen, kochen, Haushaltspflege, Wäschepflege</li>
                <li>sie informiert bei Bedarf über soziale Einrichtungen, die die Familie unterstützen können</li>
            </ul>
            </div>

            <div class="content">
            <h1 class="title is-4">Wer bezahlt diese Hilfe?</h1>

            <p>
                Die Krankenkasse, die Rentenversicherung, das Jugendamt oder das Sozialamt.
                Die Familie hat einen Eigenanteil zu tragen, der sich nach dem familiären Einkommen richtet. Näheres
                lesen Sie bitte unter dem Stichwort Eigenbeteiligung.
            </p>
            </div>

            <div class="content">
            <h1 class="title is-4">Wann werden die Kosten übernommen?</h1>

            <p>
                Wenn der Arzt die Mutter für arbeitsunfähig erklärt und dadurch die Betreuung der Kinder nicht mehr
                sichergestellt ist.
                Wenn mindestens ein Kind unter 12 Jahren im Haushalt lebt.
                Wenn keine erwachsene Person im Haushalt lebt, die die Betreuung der Kinder übernehmen könnte.
                Wenn der Vater keinen Urlaub nehmen kann.<br>
                Zum Beispiel:
            </p>

            <ul>
                <li>nach einer Geburt</li>
                <li>bei einem Krankenhausaufenthalt der Mutter</li>
                <li>bei einem Kuraufenthalt der Mutter</li>
                <li>bei ambulanter Behandlung der Mutter</li>
                <li>Begleitung eines Kindes während einer stationären Behandlung, wenn weitere Kinder zu Hause sind</li>
                <li>bei einem Kuraufenthalt der Mutter</li>
                <li>bei Verhinderungspflege, wenn Kinder erkranken und die Mutter durch Urlaub die Ausbildung, Prüfung,
                    Umschulung, das Studium oder den Arbeitsplatz gefährdet</li>
                <li>bei einem Kuraufenthalt der Mutter</li>
            </ul>

               <p> In diesen oder ähnlichen Fällen muss der Arzt einen Antrag für Haushaltshilfe stellen. In diesem Antrag
                sollte folgendes enthalten sein:<br/>
            </p>

            <ul>
                <li>Anforderung einer staatlich anerkannten Familienpflegerin und zwar <b>namentlich Familienpflege Belzer GmbH</b></li>
                <li>die voraussichtliche Zeitdauer in Tagen</li>
                <li> die nötige tägliche Stundenanzahl</li>
                <li>Begründung für die Notwendigkeit des Einsatzes der Familienpflegerin: nämlich zur Sicherstellung der
                    Betreuung der Kinder</li>
                <li>Bei akuter Notsituation sollte im Antrag die Dringlichkeit deutlich gemacht werden, da die
                    gewöhnliche Bearbeitungszeit der zuständigen Stelle meist 3 Tage beträgt.</li>
                <li>Bei akuten Notsituationen kann auch schneller reagiert werden. Zur Beschleunigung des Antrags
                    empfiehlt es sich, die Unterlagen an
                    die zuständige Stelle zu faxen oder zu mailen und um eine schriftliche (per Fax oder Mail)
                    Vorabzusage zu bitten.</li>
            </ul>

            <p>
                Gerne unterstützte ich Sie bei diesen Formalitäten!
            </p>

            <p class="bold">Die zuständigen Stellen beurteilen Ihre Situation nach Aktenlage. Daher ist es sehr wichtig
                Ihre Situation im Antrag deutlich darzustellen.</p>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        },
      methods: {
        faqJsonLd() {
          var jsonLdBreadcrumb = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Was ist Familienpflege?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Ein Hilfsangebot für Mütter, die in einer Notsituation ihre Kinder nicht versorgen können, sie\n" +
                    "                unterstützt die Familie in ihrer häuslichen Umgebung.\n" +
                    "                Von den Krankenkassen wird Familienpflege auch Haushaltshilfe genannt."
              }
            }, {
              "@type": "Question",
              "name": "Was ist eine Familienpflegerin?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "            <ul>\n" +
                    "                <li>sie betreut die Kinder</li>\n" +
                    "                <li>sie sorgt für regelmäßigen Kindergarten- und Schulbesuch der Kinder</li>\n" +
                    "                <li>sie gibt Hausaufgabenhilfe</li>\n" +
                    "                <li>sie beschäftigt die Kinder altersgerecht</li>\n" +
                    "                <li>sie begleitet die Kinder zu allen nötigen Terminen, z. B. Arzt, Logopäde, Ergotherapie,\n" +
                    "                    Musikunterricht usw.\n" +
                    "                </li>\n" +
                    "                <li>sie sorgt dafür, dass die Kinder ihre sozialen Beziehungen (Freunde, Vereine, usw.) aufrecht\n" +
                    "                    erhalten können\n" +
                    "                </li>\n" +
                    "                <li>sie übernimmt Säuglings- und Kinderpflege und Wöchnerinnenpflege</li>\n" +
                    "                <li>sie pflegt und betreut auch alle anderen Familienmitglieder ob erkrankt oder behindert</li>\n" +
                    "                <li>sie führt den Haushalt: einkaufen, kochen, Haushaltspflege, Wäschepflege</li>\n" +
                    "                <li>sie informiert bei Bedarf über soziale Einrichtungen, die die Familie unterstützen können</li>\n" +
                    "            </ul>"
              }
            }, {
              "@type": "Question",
              "name": "Wer bezahlt diese Hilfe?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "            <p>\n" +
                    "                Die Krankenkasse, die Rentenversicherung, das Jugendamt oder das Sozialamt.\n" +
                    "                Die Familie hat einen Eigenanteil zu tragen, der sich nach dem familiären Einkommen richtet. Näheres\n" +
                    "                lesen Sie bitte unter dem Stichwort Eigenbeteiligung.\n" +
                    "            </p>"
              }
            }, {
              "@type": "Question",
              "name": "Wann werden die Kosten übernommen?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": " <p>\n" +
                    "                Wenn der Arzt die Mutter für arbeitsunfähig erklärt und dadurch die Betreuung der Kinder nicht mehr\n" +
                    "                sichergestellt ist.\n" +
                    "                Wenn mindestens ein Kind unter 12 Jahren im Haushalt lebt.\n" +
                    "                Wenn keine erwachsene Person im Haushalt lebt, die die Betreuung der Kinder übernehmen könnte.\n" +
                    "                Wenn der Vater keinen Urlaub nehmen kann.<br>\n" +
                    "                Zum Beispiel:\n" +
                    "            </p>\n" +
                    "\n" +
                    "            <ul>\n" +
                    "                <li>nach einer Geburt</li>\n" +
                    "                <li>bei einem Krankenhausaufenthalt der Mutter</li>\n" +
                    "                <li>bei einem Kuraufenthalt der Mutter</li>\n" +
                    "                <li>bei ambulanter Behandlung der Mutter</li>\n" +
                    "                <li>Begleitung eines Kindes während einer stationären Behandlung, wenn weitere Kinder zu Hause sind</li>\n" +
                    "                <li>bei einem Kuraufenthalt der Mutter</li>\n" +
                    "                <li>bei Verhinderungspflege, wenn Kinder erkranken und die Mutter durch Urlaub die Ausbildung, Prüfung,\n" +
                    "                    Umschulung, das Studium oder den Arbeitsplatz gefährdet</li>\n" +
                    "                <li>bei einem Kuraufenthalt der Mutter</li>\n" +
                    "            </ul>\n" +
                    "\n" +
                    "               <p> In diesen oder ähnlichen Fällen muss der Arzt einen Antrag für Haushaltshilfe stellen. In diesem Antrag\n" +
                    "                sollte folgendes enthalten sein:<br/>\n" +
                    "            </p>\n" +
                    "\n" +
                    "            <ul>\n" +
                    "                <li>Anforderung einer staatlich anerkannten Familienpflegerin und zwar <b>namentlich Familienpflege Belzer GmbH</b></li>\n" +
                    "                <li>die voraussichtliche Zeitdauer in Tagen</li>\n" +
                    "                <li> die nötige tägliche Stundenanzahl</li>\n" +
                    "                <li>Begründung für die Notwendigkeit des Einsatzes der Familienpflegerin: nämlich zur Sicherstellung der\n" +
                    "                    Betreuung der Kinder</li>\n" +
                    "                <li>Bei akuter Notsituation sollte im Antrag die Dringlichkeit deutlich gemacht werden, da die\n" +
                    "                    gewöhnliche Bearbeitungszeit der zuständigen Stelle meist 3 Tage beträgt.</li>\n" +
                    "                <li>Bei akuten Notsituationen kann auch schneller reagiert werden. Zur Beschleunigung des Antrags\n" +
                    "                    empfiehlt es sich, die Unterlagen an\n" +
                    "                    die zuständige Stelle zu faxen oder zu mailen und um eine schriftliche (per Fax oder Mail)\n" +
                    "                    Vorabzusage zu bitten.</li>\n" +
                    "            </ul>\n" +
                    "\n" +
                    "            <p>\n" +
                    "                Gerne unterstützte ich Sie bei diesen Formalitäten!\n" +
                    "            </p>\n" +
                    "\n" +
                    "            <p class=\"bold\">Die zuständigen Stellen beurteilen Ihre Situation nach Aktenlage. Daher ist es sehr wichtig\n" +
                    "                Ihre Situation im Antrag deutlich darzustellen.</p>"
              }
            }, ]
          };

          return jsonLdBreadcrumb;
        }
      }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        list-style-type: circle;
        padding-left:20px;
        margin-top:20px;
        margin-bottom:20px;
    }
</style>
